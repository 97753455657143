import { useState, useEffect, useCallback } from "react";
import API from "../common/api.js";
import { orderBy, some } from "lodash";
import usePubSub from '../common/usePubSub.js';
import * as Constants from '../common/webconstants.js';


function augmentMessages(data, allUsers, allTeams) {
    return data.map((t) => ({
        ...t,
        assignee: allUsers.find((u) => u.user_id === t.assignee_id),
        team: allTeams.find((team) => team.team_id === t.team_id),
    }));
}

function sortMessages(data, sortOrder) {
    if (sortOrder === Constants.SORT_HIGHEST_PRIORITY_FIRST) {
        return orderBy(data, ["priority"], ["asc"]);
    } else if (sortOrder === Constants.SORT_LOWEST_PRIORITY_FIRST) {
        return orderBy(data, ["priority"], ["desc"]);
    } else if (sortOrder === Constants.SORT_OLDEST_TASK_FIRST) {
        return orderBy(data, ["created_at"], ["asc"]);
    } else if (sortOrder === Constants.SORT_NEWEST_TASK_FIRST) {
        return orderBy(data, ["created_at"], ["desc"]);
    } else if (sortOrder === Constants.SORT_BY_MESSAGE_TYPE) {
        return orderBy(data, [(o) => Constants.MESSAGE_KIND_LABELS[o.kind]], ["asc"]);
    } else if (sortOrder === Constants.SORT_UNREAD_FIRST) {
        return orderBy(data, ["was_read", "created_at"], ["desc", "desc"]);
    } else {
        return data;
    }
}

function useInboxPageMessages({allUsers, allTeams, assigneeFilter, messageTypeFilter, caseNumber, searchText, readStatusFilter, sortOrder, currentUser}) {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [sortedMessages, setSortedMessages] = useState([]);
    const [errMsg, setErrMsg] = useState(null);
    usePubSub({topic: Constants.PUBSUB_TOPIC_INBOX_MESSAGE, messageHandler: handleServerMessage});

    const loadData = useCallback(async () => {
        setMessages([]);
        setLoading(true);
        setErrMsg(null);

        let data = await API.getInboxMessages();
        if (data) {
            setMessages(augmentMessages(data, allUsers, allTeams));
        } else {
            setErrMsg("Failed to load data from server. Please retry.");
        }
        setLoading(false);
    }, [allUsers, allTeams]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        let data = messages;
        // DO NOT FILTER THE DATA IF NO FILTERS ARE ASSIGNED
        // const noFilters =
        //     assigneeFilter === null && messageTypeFilter === null && caseNumber === null && searchText === null;
        // if (noFilters) {
        //     // By default, only show messages that are assigned to me, or a team I'm a member of, or I'm mentioned on, or I'm watching
        //     data = data.filter((msg) => {
        //         if (msg.assignee_id === currentUser.userID) return true;
        //         if (currentUser.teamMemberOf.includes(msg.team_id) && msg.assignee_id === null) return true;
        //         if (msg.total_mention_count > 0) return true;
        //         if (msg.is_watched_by_current_user > 0) return true;
        //         return false;
        //     });
        // }
        if (assigneeFilter) {
            const filter = Array.isArray(assigneeFilter) ? assigneeFilter : [assigneeFilter];
            if (filter.length > 0) {
                data = data.filter((msg) => {
                    return some(
                        filter.map((f) => {
                            if (f.is_user) {
                                return msg.assignee_id === parseInt(f.id);
                            } else if (f.is_team) {
                                return msg.team_id === parseInt(f.id) && msg.assignee_id === null;
                            } else if (f.id === -1) {
                                return msg.team_id === null && msg.assignee_id === null;
                            } else {
                                return true; // Should never happen
                            }
                        })
                    );
                });
            }
        }
        if (messageTypeFilter) {
            data = data.filter((msg) => msg.kind === parseInt(messageTypeFilter.value));
        }
        if (caseNumber !== null && caseNumber.length > 0) {
            data = data.filter((msg) => msg.case_number === caseNumber);
        }
        if (searchText !== null && searchText.length > 0) {
            data = data.filter((msg) => msg.subject.toLowerCase().includes(searchText.toLowerCase()));
        }
        if (readStatusFilter === Constants.READ_STATUS_UNREAD) {
            data = data.filter(msg => !msg.was_read);
        }
        setSortedMessages(sortMessages(data, sortOrder));
    }, [
        messages,
        sortOrder,
        allUsers,
        allTeams,
        assigneeFilter,
        messageTypeFilter,
        searchText,
        readStatusFilter,
        caseNumber,
        currentUser.teamMemberOf,
        currentUser.userID,
    ]);

    function handleServerMessage(topic, data) {
        if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_ADDED) {
            if ([Constants.MESSAGE_KIND_TASK_MENTION, Constants.MESSAGE_KIND_INBOX_MESSAGE_MENTION, Constants.MESSAGE_KIND_SCAN_MENTION].includes(data.kind)) return;
            const newMessages = [
                ...messages,
                { ...data, was_read: false, read_status_user_id: currentUser.userID },
            ];
            setMessages(augmentMessages(newMessages, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_UPDATED) {
            const newMessages = messages.map((msg) => {
                if (msg.msg_id === data.msg_id) {
                    if (data.read_status_user_id === currentUser.userID) {
                        return { ...data };
                    } else {
                        return { ...data, was_read: msg.was_read, read_status_user_id: msg.read_status_user_id };
                    }
                }
                return { ...msg };
            });
            setMessages(augmentMessages(newMessages, allUsers, allTeams));
        } else if (topic === Constants.PUBSUB_TOPIC_INBOX_MESSAGE_DELETED) {
            const newMessages = messages.filter((msg) => msg.msg_id !== parseInt(data.msg_id));
            setMessages(augmentMessages(newMessages, allUsers, allTeams));
        }
    }

    const setMessageAcknowledged = useCallback(
        (msg) => {
            // Update state locally for snappy user experience
            const newMessages = messages.map((m) => {
                if (m.msg_id === msg.msg_id) {
                    return { ...m, acknowledged: true, was_read: true };
                }
                return m;
            });
            setMessages(augmentMessages(newMessages, allUsers, allTeams));

            // Update on the server in the background
            API.updateInboxMessage(msg.msg_id, { acknowledged: true });
            API.markInboxMessageRead(msg.msg_id);
        },
        [messages, allTeams, allUsers]
    );

    function setMessageAssignee(msg, assignee) {
        // Update state locally for snappy user experience
        const newMessages = messages.map((m) => {
            if (m.msg_id === msg.msg_id) {
                if (assignee.is_user) {
                    return { ...m, assignee_id: assignee.id };
                } else if (assignee.is_team) {
                    return { ...m, team_id: assignee.id, assignee_id: null };
                } else {
                    return { ...m, team_id: null, assignee_id: null };
                }
            }
            return m;
        });
        setMessages(augmentMessages(newMessages, allUsers, allTeams));

        // Update on the server in the background
        const fieldsToUpdate = assignee.is_user
            ? { assignee_id: assignee.id }
            : assignee.is_team
            ? { team_id: assignee.id, assignee_id: null }
            : { team_id: null, assignee_id: null };
        API.updateInboxMessage(msg.msg_id, fieldsToUpdate);
    }

    const toggleWatched = useCallback(async (msg) => {
        // Update state locally for snappy user experience
        const newMessages = messages.map(m => {
            if (m.msg_id === msg.msg_id) {
                return {...m, is_watched_by_current_user: !m.is_watched_by_current_user};
            }
            return m;
        });
        setMessages(augmentMessages(newMessages, allUsers, allTeams));

        // Update on the server in the background
        if (msg.is_watched_by_current_user) {
            API.unwatchInboxMessage(msg.msg_id);
        } else {
            API.watchInboxMessage(msg.msg_id);
        }
    }, [allUsers, allTeams, messages]);

    return {loading, errMsg, messages: sortedMessages, setMessageAcknowledged, setMessageAssignee, toggleWatched};
}

export default useInboxPageMessages;
